import React from 'react';

const NoMatch = () => {
    return (
        <div >
            NO PAGE FOUND chnanged 
        </div>
    );
};

export default NoMatch;