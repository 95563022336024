export const serviceData = [
    {
        title: 'Software Consulting',
        services: ['Software stack assessment', 'Software architecture assessment & redesign', 'Software modernization', 'Software development', 'Team augmentation', 'Setup of DevOps Processes', 'Business process analysis', 'Software product consulting', 'QA Process setup']
    },
    {
        title: 'Product Development',
        services: ['Application planning', 'Project planning', 'Architecture design', 'UX/UI design', 'Application development', 'testing & QA']
    },
    {
        title: 'Enterprise Software',
        services: ['Enterprise application planning', 'Project planning', 'Architecture design', 'UX/UI design', 'Application development', 'testing & QA', 'App Integration', 'Legacy enterprise software modernization']
    },
    {
        title: 'Support & Maintenance',
        services: ['Web apps', 'Cloud App & SaaS', 'Database apps', 'Mobile apps', 'AR/VR apps', 'Desktop apps']
    }
]