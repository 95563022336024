import React from 'react';
import bg from '../../Img/LandingPagesBG.jpg';
import { Container, Grid, Stack } from '@mui/material';
import './font.css';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Link } from 'react-router-dom';
import Footer from '../../Components/Footer/Footer';

const landingPageStyle = {
    // width: '100vw',
    height: '94vh',
    backgroundImage: `url(${bg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: 'lightgray',
}

const LandingPage = () => {
    // const navigate = useNavigate();
    return (
        <div style={landingPageStyle}>
            <Container maxWidth="auto">
                <Grid container columns={{ xs: 12 }}>
                    <Grid item sm={12} md={4}>
                        <Stack 
                        style={{height: '80vh'}}
                            direction="column"
                            justifyContent="center"
                            //   alignItems="center"
                            spacing={2}
                        >
                            <div className='fontDisplay technoText mb-3'>techno</div>
                            <div className='fontDisplay banglaText'>BANGLA</div>
                            <div className="openText mb-0">you think</div> 
                            <div className="openText m-5 mt-0 mb-0">we create</div>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={4}>
                        <Stack style={{height: '8vh'}}
                            direction="row" spacing={4} >
                           <Link to="https://www.google.com" target='_blank' className='iconLink'><LinkedInIcon/></Link>
                           <Link to="https://www.google.com" target='_blank' className='iconLink'><FacebookIcon/></Link>
                           <Link to="https://www.google.com" target='_blank' className='iconLink'><WhatsAppIcon/></Link>
                        </Stack>
                    </Grid>
                </Grid>
                <Footer/>
            </Container>
        </div>
    );
};

export default LandingPage;
