import { createBrowserRouter } from "react-router-dom";
import Layout from "../Layout";
import LandingPage from "../Pages/LandingPage/LandingPage";
import NoMatch from "../Pages/NoPage/NoMatch";
import About from "../Pages/About/About";
import Services from "../Pages/Service/Services";
import Contact from "../Pages/Contact/Contact";
import Client from "../Pages/Client/Client";

const router = createBrowserRouter([
    {
        path: '/',
        element:
            <Layout />,
        children: [
            {
                path: '',
                element: <LandingPage />
            },
            {
                path: 'home',
                element: <LandingPage />
            },
            {
                path: 'about',
                element: <About />
            },
            {
                path: 'service',
                element: <Services />
            },
            {
                path: 'client',
                element: <Client />
            },
            {
                path: 'contact',
                element: <Contact />
            },
            {
                path: '*',
                element: <NoMatch />
            }
        ]
    }
])

export default router;