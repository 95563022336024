// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fontDisplay {
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
  }
  
.technoText{
    font-weight: 400;
    /* font-size: 60px; */
    font-size: 2.75rem;
}

.banglaText{    
    font-weight: 550;
    /* font-size: 80px; */
    font-size: 4rem;
}

.openText{
    letter-spacing: .93rem;
    font-size: .80rem;
}

.iconLink{
    text-decoration: none;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/Pages/LandingPage/font.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,yBAAyB;IACzB,kBAAkB;EACpB;;AAEF;IACI,gBAAgB;IAChB,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,YAAY;AAChB","sourcesContent":[".fontDisplay {\r\n    font-family: \"Rubik\", sans-serif;\r\n    font-optical-sizing: auto;\r\n    font-style: normal;\r\n  }\r\n  \r\n.technoText{\r\n    font-weight: 400;\r\n    /* font-size: 60px; */\r\n    font-size: 2.75rem;\r\n}\r\n\r\n.banglaText{    \r\n    font-weight: 550;\r\n    /* font-size: 80px; */\r\n    font-size: 4rem;\r\n}\r\n\r\n.openText{\r\n    letter-spacing: .93rem;\r\n    font-size: .80rem;\r\n}\r\n\r\n.iconLink{\r\n    text-decoration: none;\r\n    color: white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
