import { Grid, Stack } from '@mui/material';
import React from 'react';
import './font.css';

const Footer = () => {
    return (
        <div>
            <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack style={{ height: '5vh' }}
                            direction="row" spacing={4} 
                            justifyContent="center"
                             alignItems="center">
                            <div className="barCodeText">
                                TECHNO BANGLA
                            </div>
                        </Stack>
                    </Grid>
                </Grid>
        </div>
    );
};

export default Footer;