import { Container, Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import Footer from '../../Components/Footer/Footer';
import { clientData } from '../../Data/ClientData';

const clientPageStyle = {
    height: '94vh',
    // backgroundColor: "#121818",
    // backgroundColor: "white",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: 'gray',
}

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "none" }}
        // onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "none" }}
        // onClick={onClick}
        />
    );
}


const Client = () => {
    const reviewSettings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        autoplay: true,
        speed: 4000,
        autoplaySpeed: 3000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const settings = {
        infinite: true,
        slidesToShow: 6,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: false,
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 425,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                },
            },
        ],
    };

    const [showNav, setShowNav] = useState(true);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const resizeHandle = () => {
        setScreenWidth(window.innerWidth)
    }

    useEffect(() => {
        if (window.innerWidth < 1210) {
            setShowNav(false)
        }
        else setShowNav(true)
        window.addEventListener('resize', resizeHandle);
    }, [screenWidth])



    return (
        <div style={clientPageStyle}>
            {showNav ?
                <Stack style={{ height: '68vh' }}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}>
                    <p className='fontDisplay mt-3'>TechnoBangla has rendered services over the years to more than 20 renowned organizations.</p>
                    <div style={{
                        height: '68vh',
                        //  backgroundColor: "#121818" 
                    }}>
                        <Container className='mt-2'>
                            <Slider {...reviewSettings}>
                                {clientData.map((row, index) => (
                                    <div key={index}>
                                        <Stack direction='row' justifyContent="center" alignItems="center">
                                            <div className="shadow m-3 p-5 bg-light d-flex justify-content-between" style={{ height: '40vh', width: '50vw' }}>
                                                <div style={{ width: "70%" }}>
                                                    {row.review}
                                                    <br /><br />
                                                    <span className='fontDisplay openText'>- {row.title}</span>
                                                </div>
                                                <div>
                                                    <img src={row.imgSrc} alt={row.title} className='img-fluid' style={{ height: "60px" }} />
                                                </div>
                                            </div>
                                        </Stack>
                                    </div>
                                ))}
                            </Slider>
                        </Container>
                    </div>
                </Stack>
                :

                <div>
                    <Stack direction='column' justifyContent="center" alignItems="center" >
                        {clientData.map((row, index) => (
                            <div className="m-3" style={{ height: '20vh', width: '80vw', overflow: 'auto', textAlign: 'justify' }}>
                                <div>
                                    <img src={row.imgSrc} alt={row.title} className='img-fluid' style={{ height: "2rem" }} /> <span className='fontDisplay'><b>{row.title}</b></span>
                                </div>
                                <br/>
                                <div>
                                    {row.review}
                                </div>
                            </div>
                        ))}
                    </Stack>
                </div>

            }

            <Container maxWidth='auto' className="slider-container"
                style={{ height: '14vh', backgroundColor: 'white' }}
            >
                <Slider {...settings}>
                    {
                        clientData.map((row, index) => (
                            <div key={index} className='d-flex justify-content-center m-3 p-3' >
                                <img src={row.imgSrc} alt={row.title} className='img-fluid' style={{ height: "3.5rem", filter: 'grayscale(100%)' }} />
                            </div>
                        ))
                    }
                </Slider>
            </Container>
            <Footer />
        </div>
    );
};

export default Client;