export const productData = [
    {
        title: 'ERP',
        name: 'Enterprise Resource Planning',
        desc: 'Enterprise resource planning systems are complete, integrated platforms, either on-premises or in the cloud, managing all aspects of a production-based or distribution business. Furthermore, ERP systems support all aspects of financial management, human resources, supply chain management, and manufacturing with your core accounting function. ERP systems will also provide transparency into your complete business process by tracking all aspects of production, logistics, and financials. These integrated systems act as a business’s central hub for end-to-end workflow and data, allowing a variety of departments to access. ERP Systems and software support multiple functions across the enterprise, mid-sized, or small businesses, including customizations for your industry.'
    },
    {
        title: 'FA',
        name: 'Fixed Asset Management',
        desc: 'Asset management software helps organizations maximize asset utilization while minimizing the cost of owning and using assets. The best asset management software makes it easy to audit and track your assets as well as important asset details, such as purchasing and licensing fees, maintenance dates, asset location, usage compliance, user permissions, contract renewal dates and usage patterns. The very best asset tracking solutions will help you save more money than you’ll pay for the software.'
    },
    {
        title: 'CRM',
        name: 'Customer Relationship Management',
        desc: 'Nurture your potential LEAD to OPPORTUNITY with all the efficient process flow to see conversion to customer and achieve highest satisfaction level of customers through high standard services.'
    },
    {
        title: 'IMS',
        name: 'Inventory Management',
        desc: 'Sortly inventory tracking software makes it easy to manage your entire inventory—on any device, anywhere. Manage inventory easily from your smartphone, tablet, or desktop. Track supplies, materials, parts, equipment, tools, or any other type of inventory. Perfect for small business inventory management.'
    },
    {
        title: 'POS',
        name: 'Point of Sales',
        desc: 'A point of sale, or point of purchase, is where you ring up customers and accept payments. When customers check out online, walk up to your checkout counter, or pick out an item from your stand or booth, they’re at the point of sale. You can even do this on existing hardware you already use for your business—your computer.Your point-of-sale system is the hardware and software that enable your business to make those sales.'
    },
    {
        title: 'HRMS',
        name: 'Human Resource Management System',
        desc: 'HR management operations are not seen lightly as they will lead to catastrophic effects if not done with dedicated and advanced tools. The HRMS software will act as the definite solution to run all the HR management operations of your company. The implementation of the HRMS software will initially start with pre-requisite planning based on the analysis and requirements that the software can meet in your company operations. Followed by the development of the platform and making it compatible and customizing to suit your operational requirements. Moreover, the final step involves implementing the software into live operations followed by ultimate testing and check on reliability and efficiency. We won’t leave you there with the completed product rather we will train you and will be there to support you in desperate times and needs regarding the HRMS software.'
    },

]
