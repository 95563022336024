export const aboutData = [
    {
        Title: 'ABOUT US',
        firstHalf: `TechnoBangla creates innovative software solutions for global markets using cutting-edge technology and a dedicated team of experts. As a leading software engineering firm, we're committed to exceeding customer expectations with user-friendly software and extensive support. Our values of respect, empathy, fairness, integrity, and trust guide our professionalism and innovation, ensuring our position as a top global software solutions provider.
        We believe in the following values to guide our conduct within the company and toward our valued customers:`,
        lineText: 'Honesty-- Respect -- Empathy -- Fairness -- Integrity -- Trust --',
        lastHalf: ` We strive to maintain the highest level of professionalism through teamwork and constant innovation, ensuring that we continue to be the go-to provider of software solutions worldwide.`
    },
    {
        Title: 'IT & CONSULTING FIRM',
        firstHalf: `TechnoBangla is a leading software engineering firm that is committed to delivering high-quality applications that adhere to international standards. The firm's professional software engineering process guides clients through the phases of analysis, design, implementation, and maintenance, ensuring that they receive the best possible service.

        The in-house professionals at TechnoBangla provide users of the software with comprehensive training, support, and guidance, which maintains clear communication with clients throughout the system development life cycle. This ensures that clients have a positive experience and receive a product that meets all their needs.

        Moreover, TechnoBangla also provides specialized consultancy in Information Technology and Enterprise Business Solutions. Whether it's Wide Area Networking or any aspect of software engineering, such as Software Architecture, Programming, Database Administration, or Website design, the firm has the expertise to provide the best possible solution.
        TechnoBangla consulting services cover, but are not limited to the following:`,
        lineText: '– IT Solution – Software Engineering – Computer – Networking – Business Automation –',
        lastHalf: null
    },
    {
        Title: 'PROFILE',
        firstHalf: `TechnoBangla is a reputable software engineering and IT consulting firm that specializes in developing custom software solutions for organizations. From small to large-scale businesses, TechnoBangla has a proven track record of meeting its customers' requirements dependably and cost-effectively.
        Since its inception in 2001, TechnoBangla has grown to become one of the most successful IT companies in Bangladesh. The company's knowledge of information systems is extensive and up-to-date, and its team has the competencies and experience necessary to deliver top-quality IT solutions rapidly and effectively.
        With business partners located throughout Bangladesh, TechnoBangla has made its mark as a reliable and trustworthy IT partner. Allow TechnoBangla to assist you in achieving your technological goals and take your business to new heights.`,
        lineText: null,
        lastHalf: null
    }
]