import { Container, Drawer, IconButton, List, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Barstyle.css';
import logo from '../../Img/logo.svg';

const headerBarStyle = {
    height: "6vh",
    // Width: "100vw",
    backgroundColor: "#111312",
    letterSpacing: "1px",
    borderBottom: "solid 3px #111312",
    color: "#cac0b6",
}

const HeaderBar = () => {
    const navigate = useNavigate();
    const navData = [
        { title: 'Home', path: '/home' },
        { title: 'About', path: '/about' },
        // { title: 'Product', path: '/product' },
        { title: 'Service', path: '/service' },
        { title: 'Client', path: '/client' },
        { title: 'Contact', path: '/contact' },

    ]
    const [hoverProperty, setHoverProperty] = useState({})

    const mouseEnterHandle = (nameKey) => {
        setHoverProperty({ [nameKey]: true })
    }

    const mouseLeaveHandle = (e) => {
        setHoverProperty({})
    }
    const styleHover = {
        borderBottom: "solid 3px #004a68",
        color: "lightgray"
    }

    const [open, setOpen] = useState(false)

    return (
        <Container maxWidth="auto" style={headerBarStyle}>
            {<Stack direction={{ sm: 'column', md: 'row' }}
                style={headerBarStyle}
            >
                <List sx={{ visibility: { xs: 'visible', md: 'hidden' } }}><img src={logo} alt="logo" className='img-fluid' style={{ height: '3vh' }} onClick={(e) => setOpen(true)} /></List>
                <List sx={{ visibility: { xs: 'hidden', md: 'visible' } }}><img src={logo} alt="logo" className='img-fluid' style={{ height: '3vh' }} /></List>
                {navData.map((row, index) => (
                    <List sx={{ visibility: { xs: 'hidden', md: 'visible' } }} key={index} className="mt-1 px-5" onClick={(e) => { navigate(row.path) }} onMouseEnter={(e) => { mouseEnterHandle(row.title) }} onMouseLeave={(e) => mouseLeaveHandle()}
                        style={hoverProperty[row.title] && window.location.pathname !== row.path ? styleHover : null}
                        id={window.location.pathname === row.path ? "activePath" : null} >{row.title}</List>
                ))}

            </Stack>}


            <Drawer
                PaperProps={{
                    sx: {
                        backgroundColor: "#111312",
                        color: "lightgray",
                    }
                }}
                open={open} anchor='right' onClose={(e) => setOpen(false)}>
                {/* <IconButton onClick={(e) => setOpen(false)}>

                </IconButton> */}
                <div className='m-3 p-3 ' >
                    {navData.map((row, index) => (
                        <List key={index} className="mt-1 px-5" onClick={(e) => { navigate(row.path); setOpen(false) }} onMouseEnter={(e) => { mouseEnterHandle(row.title) }} onMouseLeave={(e) => mouseLeaveHandle()}
                            style={hoverProperty[row.title] && window.location.pathname !== row.path ? styleHover : null}
                            id={window.location.pathname === row.path ? "activePath" : null} >{row.title}</List>
                    ))}

                </div>
            </Drawer>

        </Container>
    );
};

export default HeaderBar;