// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.barCodeText {
    font-family: "Libre Barcode 128 Text", system-ui;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
  }`, "",{"version":3,"sources":["webpack://./src/Components/Footer/font.css"],"names":[],"mappings":"AAAA;IACI,gDAAgD;IAChD,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;EACjB","sourcesContent":[".barCodeText {\r\n    font-family: \"Libre Barcode 128 Text\", system-ui;\r\n    font-weight: 400;\r\n    font-style: normal;\r\n    font-size: 20px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
