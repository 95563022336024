import React from 'react';
import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import HeaderBar from './Components/HeaderBar/HeaderBar';
import Footer from './Components/Footer/Footer';

const Layout = () => {
    return (
        <div>
            <Stack direction='column' spacing={0}
            // style={{backgroundColor: "#111312"}}
            >
                 <HeaderBar/>
                 <Outlet/>
             </Stack>
         </div>
    );
};

export default Layout;