import { Container, Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Footer from '../../Components/Footer/Footer';
import Slider from "react-slick";
import { aboutData } from '../../Data/AboutData';

const aboutPageStyle = {
    height: '94vh',
    backgroundColor: "#121818",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: 'lightgray',
}

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "none" }}
        // onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "none" }}
        // onClick={onClick}
        />
    );
}

const settings = {
    dots: true,
    customPaging: i => (
        <div
            style={{
                margin: '2px',
                padding: '2px',
                width: '1.5rem',
                borderRadius: '10%',
                backgroundColor: "gray",
                // border: "1px lightgray solid"
            }}
        >
            {/* {i + 1} */}
        </div>
    ),
    infinite: true,
    slidesToShow: 1,
    focusOnSelect: true,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
};

const About = () => {
    const [showNav, setShowNav] = useState(true);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const resizeHandle = () => {
        setScreenWidth(window.innerWidth)
    }

    useEffect(() => {
        if (window.innerWidth < 950) {
            setShowNav(false)
        }
        else setShowNav(true)
        window.addEventListener('resize', resizeHandle);
        // return () => window.removeEventListener('resize', resizeHandle);
    }, [screenWidth])

    return (
        <div style={aboutPageStyle}>
            <Container maxWidth="auto">
                <Grid container>
                    <Grid item md={12} lg={9}>
                        {
                            showNav ? <Slider {...settings} style={{ height: '70vh' }}>

                                {aboutData.map((row, index) => (

                                    <div key={index} className='m-5 p-5'>
                                        <div className='fontDisplay technoText mb-3'>{row.Title}</div>
                                        <div >{row.firstHalf}
                                            <p className='mt-1 mb-1' style={{ textAlign: "center" }}>{row.lineText}</p>
                                            {row.lastHalf}</div>

                                    </div>
                                ))}

                            </Slider>
                                :
                                <div style={{ height: '88vh', textAlign: 'center', overflowY: 'auto' }}>
                                    {aboutData.map((row, index) => (
                                        <div key={index} className='m-3 p-3'>
                                            <h6>{row.Title}</h6>
                                            <p >{row.firstHalf}
                                                <p>{row.lineText}</p>
                                                {row.lastHalf}</p>
                                        </div>
                                    ))}
                                </div>
                        }
                    </Grid>
                </Grid>
                <div className="div" style={{ height: '18vh' }}></div>
                <Footer />
            </Container>
        </div>
    );
};

export default About;