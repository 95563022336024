import React, { useEffect } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { Alert, Button, Container, Grid, Snackbar, Stack, styled, TextField } from '@mui/material';
import Footer from '../../Components/Footer/Footer';
import { useState } from 'react';
import bg from '../../Img/ContactPageBG.jpg';

const WhiteBorderTextField = styled(TextField)`
  & label {
    color: lightgray;
  },
  & label.Mui-focused {
    color: lightgray;
  },
  & .MuiInput-underline:before {
    border-color: lightgray;
  } 
  & .MuiInput-underline:after {
    border-color: lightgray;
  }
  & .MuiInput-underline::hover{
    border-color: lightgray;
  }
`;

const contactPageStyle = {
    height: '94vh',
    // backgroundColor: "#121818",
    backgroundImage: `url(${bg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    color: 'lightgray',
    overflowY: 'auto'
}
const Contact = () => {
    const [openAlert, setOpenAlert] = useState(false);
    const [msgText, setMsgText] = useState({});
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };

    const [state, handleSubmit] = useForm("mrgnroaa");
    useEffect(() => {
        if (state.succeeded) {
            setOpenAlert(true);
            setMsgText({ status: true, msg: "Your action has been successfully executed" });
        }

        setInterval(() => {
            setOpenAlert(false)
        }, 3000);

    }, [openAlert, state.succeeded])

    return (
        <>
            <Snackbar
                open={openAlert}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleClose}
                    severity={msgText.status ? "info" : "error"}
                    variant="filled"
                    sx={{ width: '100%' }}
                >{msgText.msg}</Alert>
            </Snackbar>

            <div style={contactPageStyle}>
                <Container >
                    <Grid container spacing={4} className='fontDisplay' style={{ color: 'lightgray' }}>
                        <Grid item sm={12} md={6}>
                            <Stack style={{ height: '88vh', textAlign: 'center' }} direction="column" justifyContent="center">
                                <div className='technoText mb-3'>CONTACT US</div>
                                <div className='mb-5'>Today's world is running with constant turbulence, rapid market changes, increasing customer demands 
                                and global competition. Information technology is at the center of our daily work. 
                                You need a reliable and professional IT solution provider to help you rise and stay ahead of the 
                                challenges of the 21st century.</div>

                                <div className="mt2 mb-2">
                                    <h6 className='mb-0'>ADDRESS</h6>                                     
                                    <p>House-51/B Borak Mehnur, Kemal Ataturk Ave, Banani, Dhaka 1213</p>
                                </div>

                                <div className="mt2 mb-2">
                                    <h6 className='mb-0'>PHONE</h6>
                                    <p>+880 17 960 95 29</p>
                                </div>

                                <div className="mt2 mb-2">
                                    <h6 className='mb-0'>E-MAIL</h6>
                                    <p>info@technobangla.com.bd</p>
                                </div>
                            </Stack>
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <Stack style={{ height: '88vh' }} direction="column" justifyContent="center">
                                <div className="m-5 p-5 shadow" style={{ backgroundColor: "#121818", opacity:'0.6'}}>
                                    <form onSubmit={handleSubmit}>                                        
                                        <WhiteBorderTextField label="your name" type="text"
                                            autoComplete='off'
                                            id="name"
                                            name="name"
                                            fullWidth
                                            variant="standard"
                                            size="small"
                                            inputProps={{ style: { color: 'lightgray', backgroundColor: 'none' } }}
                                        />
                                        <ValidationError
                                            prefix="name"
                                            field="name"
                                            errors={state.errors}
                                        />

                                        <WhiteBorderTextField label="your email" type="text"
                                            autoComplete='off'
                                            id="email"
                                            name="email"
                                            fullWidth
                                            variant="standard"
                                            size="small" required
                                            inputProps={{ style: { color: 'lightgray', backgroundColor: 'none' } }}
                                        />

                                        <ValidationError
                                            prefix="email"
                                            field="email"
                                            errors={state.errors}
                                        />


                                        <WhiteBorderTextField label="your message" type="text"
                                            autoComplete='off'
                                            id="massage"
                                            name="massage"
                                            fullWidth
                                            variant="standard"
                                            size="small"
                                            inputProps={{ style: { color: 'lightgray', backgroundColor: 'none' } }}
                                        />
                                        <ValidationError
                                            prefix="Message"
                                            field="message"
                                            errors={state.errors}
                                        />
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-end">
                                            <Button type="submit" disabled={state.submitting} style={{ color: "#05adf3" }}>
                                                Submit
                                            </Button>
                                        </Stack>
                                    </form>
                                </div>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Footer />
                </Container>
            </div>


        </>
    )
};

export default Contact;