import { Avatar, Container, Drawer, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Footer from '../../Components/Footer/Footer';
import bg from '../../Img/ServicePagesBG.jpg';
import Slider from "react-slick";
import CheckIcon from '@mui/icons-material/Check';
import { serviceData } from '../../Data/ServiceData';
import { productData } from '../../Data/ProductData';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const productPageStyle = {
    height: '94vh',
    // width: '100vw',
    // backgroundColor: "#121818",
    backgroundImage: `url(${bg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: 'lightgray',
    overflow: 'auto'
}

const Services = () => {
    const productSettings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        autoplay: true,
        speed: 4000,
        autoplaySpeed: 3000,
    };


    const [open, setOpen] = React.useState(false);
    const [displayDiv, setDisplayDiv] = React.useState('')

    const handleDrawer = (textPaziSir) => {
        setDisplayDiv(textPaziSir);
        setOpen(true);
    }

    const [showNav, setShowNav] = useState(true);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const resizeHandle = () => {
        setScreenWidth(window.innerWidth)
    }

    useEffect(() => {
        if (window.innerWidth < 1300) {
            setShowNav(false)
        }
        else setShowNav(true)
        window.addEventListener('resize', resizeHandle);
    }, [screenWidth])


    return (
        <div style={productPageStyle}>
            <Container maxWidth="auto">
                {showNav ?
                    <Grid container >
                        <Grid item xs={12}>
                            <Stack style={{ height: '88vh' }}
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}>
                                <div style={{
                                    height: '30vh',
                                    //  backgroundColor: "#121818" 
                                }}>
                                    <Container className='mb-2'>
                                        <Slider {...productSettings}>
                                            {productData.map((row, index) => (
                                                <div key={index} className='p-5' style={{ backgroundColor: '#121818', opacity: '0.8', color: 'lightgray', width: '800px' }}>
                                                    <h5>{row.title}</h5>
                                                    <h6>{row.name}</h6>
                                                    <p>{row.desc.slice(0, 100)}
                                                        <button style={{ border: 'none', backgroundColor: 'transparent', color: '#05adf3', textAlign: 'left' }}
                                                            onClick={(e) => handleDrawer(row)}>CLICK TO SEE MORE</button></p>
                                                    <Drawer open={open} anchor='bottom' onClose={(e) => setOpen(false)}>
                                                        <div className='m-5 p-5' >
                                                            <h5>{displayDiv.title}</h5>
                                                            <h6>{displayDiv.name}</h6>
                                                            <p>{displayDiv.desc}</p>
                                                        </div>
                                                    </Drawer>
                                                </div>
                                            ))}
                                        </Slider>
                                    </Container>
                                </div>
                                <Container maxWidth='auto' className="slider-container">
                                    <Grid container spacing={2}>
                                        {serviceData.map((row, index) => (

                                            <Grid key={index} item xs={3}>
                                                <List dense={true}>
                                                    <ListItem disablePadding>
                                                        <ListItemAvatar>
                                                            <Avatar sx={{ bgcolor: '#05adf3' }}>
                                                                {index + 1}
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText>
                                                            {row.title}
                                                        </ListItemText>
                                                    </ListItem>
                                                    {row.services.map((li, i) => (
                                                        <ListItem disablePadding className='p-2 pt-0 pb-0'>
                                                            <ListItemIcon>
                                                                <CheckIcon style={{ color: 'lightgray' }} />
                                                            </ListItemIcon>
                                                            <ListItemText key={i} primary={li} />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Container>
                            </Stack>
                        </Grid>
                    </Grid>
                    : productData.map((row, index) => (
                        <>
                            <div key={index} className='p-5' style={{ backgroundColor: '#121818', opacity: '0.8', color: 'lightgray' }}>
                                <h5>{row.title}</h5>
                                <h6>{row.name}</h6>
                                <p>{row.desc.slice(0, 100)}
                                    <button style={{ border: 'none', backgroundColor: 'transparent', color: '#05adf3', textAlign: 'left' }}
                                        onClick={(e) => handleDrawer(row)}>... CLICK TO SEE MORE</button></p>
                                <Drawer open={open} anchor='bottom' onClose={(e) => setOpen(false)}>
                                    <IconButton onClick={(e) => setOpen(false)}>
                                        <ArrowDropUpIcon />
                                    </IconButton>
                                    <div className='m-3 p-3 ' >
                                        <h5>{displayDiv.title}</h5>
                                        <h6>{displayDiv.name}</h6>
                                        <p>{displayDiv.desc}</p>
                                    </div>
                                </Drawer>
                            </div>
                            <br />
                        </>
                    ))}

                <Footer />

            </Container >
        </div >
    );
};

export default Services;