export const clientData = [
    {
        title: "Bangladesh Government",
        imgSrc: "https://techedu.gov.bd/themes/responsive_npf/img/logo/logo.png",
        review: "greWi-Fi is a wireless networking technology that allows devices such as computers"
    },
    {
        title: "Cotto",
        imgSrc: "https://www.cotto.com/common/img/003/cotto-logo.png",
        review: "greWi-Fi is a wireless networking technology that allows devices such as computers"
    },
    {
        title: "Dx Group",
        imgSrc: "https://www.dx.com.bd/_next/image?url=https%3A%2F%2Fapi.dx.com.bd%2Fstorage%2Fuploads%2Flogo-DX-Logo-01.png-qseN.png&w=96&q=75",
        review: "greWi-Fi is a wireless networking technology that allows devices such as computers"
    },
    {
        title: "SEBL",
        imgSrc: "https://www.solarelectrobd.com/extra-images/SEBL-Logo.png",
        review: "greWi-Fi is a wireless networking technology that allows devices such as computers"
    },
    {
        title: "GREAT WALL",
        imgSrc: "https://scontent.fdac157-1.fna.fbcdn.net/v/t39.30808-1/347795824_579997380864821_6608069006271619281_n.png?stp=dst-png_p200x200&_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_eui2=AeFnzzwrmkFLhtgpPoETo2gFVFNSnsbMOfNUU1Kexsw583zHIFLgBxgsOn8pHTN8pl_qcRCWXRei1SwGNhMrf7Pk&_nc_ohc=DouSbc02ZygQ7kNvgHz1aaF&_nc_ht=scontent.fdac157-1.fna&oh=00_AYBu56CwTx28OCHnGqKdGGNk0NoAB3d2ZhLYGxT6po7dbw&oe=664A2EDB",
        review: "greWi-Fi is a wireless networking technology that allows devices such as computers"
    },
    {
        title: "CHARU",
        imgSrc: "https://charuceramic.net/wp-content/uploads/2022/02/Charu_Style_Living.png",
        review: "greWi-Fi is a wireless networking technology that allows devices such as computers"
    },
]
